import { useState } from 'react'
import { Layout, Menu, MenuProps } from "antd"
import styled, { css } from "styled-components"
import FireflyLogoFly from 'assets/firefly-fly_60px.png'
import FireflyLogoText from 'assets/firefly-text_60px.png'
import { isMobile600, isNotebook } from 'utils/isMobile'
import { createGlobalStyle } from 'styled-components';

const { Sider } = Layout


const GlobalStyle = createGlobalStyle`
  .ant-layout-sider-zero-width-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    background: #2d517354;
    color: black;
    height: 64px;
    z-index: 1;

    :hover {
      background: #2d517399;
    }
    overflow: auto;
  }
  .ant-menu-item {
    a {
      color: #000000;
      opacity: 0.8;
    }
  }
`

const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0px 0px 16px 0px rgba(255,255,255,0.1);
`;

const LogoImg = styled.img`
  height: 45px;
  /* margin: 18px; */
  
`;

const LogoText = styled.img<{ hidden?: boolean }>`
  /* height: 42px; */
  ${p => p.hidden && css`
    visibility: hidden;
    opacity: 0;
  `}
  max-width: 130px;
  margin-left: 12px;
  /* margin: 16px; */
  /* margin-left: 0; */
  transition: visibility 0s, opacity 1s linear;
`;

export interface Props {
  menuItems: MenuProps['items']
}

const DashboardLeftSider = (props: Props) => {
  const [collapsed, setCollapsed] = useState(isNotebook() ? true : false)

  return <>
    <GlobalStyle />
    <Sider
      collapsible
      breakpoint="xxl"
      collapsed={collapsed}
      onCollapse={(collapsed: boolean) => setCollapsed(collapsed)}
      collapsedWidth={isMobile600() ? 0 : 80}
      width={256}
      zeroWidthTriggerStyle={{ top: 12 }}
    >
      <LogoDiv>
        <div style={{ display: 'inline-block' }}>
          <LogoImg src={FireflyLogoFly} alt="FireFly logo" />
          <LogoText src={FireflyLogoText} alt="FireFly text" hidden={collapsed} />
        </div>
      </LogoDiv>
      <Menu
        defaultSelectedKeys={['ChatHistory']}
        theme="dark"
        mode="inline"
        items={props.menuItems}
      />
    </Sider>
  </>
}

export default DashboardLeftSider;
