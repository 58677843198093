import { Component } from 'react'

export interface Props {
  /** fallback for code exceptions */
  errorFallback: JSX.Element
  /** fallback for network errors */
  networkErrorFallback: JSX.Element
}
export interface State {
  errorType: 'error' | 'networkError'
  hasError: boolean
}
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      errorType: null,
      hasError: false
    }
  }

  static isNetworkError(error: any): boolean {
    if (!error) return null
    const message: string = error.message || ''
    return message.toLowerCase().includes('network') || error.name === 'ChunkLoadError'
  }

  static getDerivedStateFromError(error: any): State {
    return {
      errorType: ErrorBoundary.isNetworkError(error) ? 'networkError' : 'error',
      hasError: true
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo)
    // console.log(error.message)
    // console.log(JSON.stringify(error))
  }

  render() {
    if (this.state.hasError) {
      if (this.state.errorType === 'error')
        return this.props.errorFallback
      if (this.state.errorType === 'networkError')
        return this.props.networkErrorFallback
    }
    return this.props.children
  }
}

export default ErrorBoundary
