import gql from 'graphql-tag'
import { EXTERNAL_CHAT_CHANNEL_FRAGMENT } from 'graphql/Chats/ExternalChatChannelFragments'

export const CUSTOM_REACTIVE_CHAT_QUESTIONS_FRAGMENT = gql`
fragment CustomReactiveChatQuestionsFragment on RealEstateAgencyChild {
  customReactiveChatQuestions {
    position
    mustBeAQuestion
    ommitOtherReactiveResponses
    isButtonOnly
    flowToRedirect
    fullQuestion
    keywords
    stepsToShow
    stopBot
    answer
    id
    showOnlyInWidgets {
      id
      name
    }
    tagsToAddToClient
    realEstateRentToAddToClient
    realEstateBuyToAddToClient
  }
}
`

export const CUSTOM_CHAT_STEPS_FRAGMENT = gql`
fragment CustomChatStepsFragment on RealEstateAgencyChild {
  customChatSteps {
    id
    messageIn
    messageOut
    subFlow
    stopBot
    flowToRedirect
  }
}
`

export const SALES_GROUPS_FRAGMENT = gql`
fragment SalesGroupsFragment on RealEstateAgencyChild {
  salesGroups {
    id
    agencyChildId
    activePeriods
    activeWeekdays
    activeMonthDays
    activeTags
    forbiddenTags
    activeDDDs
    forbiddenDDDs
    disabledDealType
    isActive

    leadsCount
    salespeopleEmails
    allowedEmails
    defaultPropertyRaiserEmail
  }
}
`

export const FUNNEL_STEP_FRAGMENT = gql`
fragment FunnelStepFragment on FunnelStep {
  id
  name
  position
  color
  isFinal
  isUnShareDestiny
  daysToNotify
  description
  idInCrm
}
`

export const DEPARTMENT_FRAGMENT = gql`
fragment DepartmentFragment on Department {
  id
  name
  tags
}
`

export const CUSTOM_AUX_TAG_TEXT_FRAGMENT = gql`
fragment CustomAuxTagTextFragment on CustomAuxTagText {
  id
  text
  auxTag
}
`

export const CUSTOM_ROULETTE_PERIOD_FRAGMENT = gql`
fragment CustomRoulettePeriodFragment on RoulettePeriod {
  id
  period
  weekDay
  start
  end
}
`

export const AGENCY_OBSERVATION_FRAGMENT = gql`
fragment AgencyObservationFragment on AgencyObservation {
  id
  text
  user {
    id
    name
    email
  }
  timestamp
}
`

export const AGENCY_TEMPLATE_FRAGMENT = gql`
fragment AgencyTemplateFragment on AgencyTemplate {
  id
  text
  title
  showOnlyInWidgetIds
}
`

export const REAL_ESTATE_AGENCY_CHILD_FRAGMENT = gql`
fragment RealEstateAgencyChildFragment on RealEstateAgencyChild {
  id
  idInAgencyParent
  crmAgencyCode
  crmEnabled
  crmSendMethod
  crmSubdomain
  crmToken
  crmUsername
  crmPassword
  crmChannelName
  crmChannelName2
  crmSendLastConversation
  crmDefaultBuyRealEstate
  crmDefaultRentRealEstate
  crmDefaultEnterpriseRealEstate
  crmDefaultRealEstateCode
  crmQueueName
  crmSetAsOpportunity
  crmType
  resendToCrm
  resendEmail
  crmDisablesAngariation
  crmDisablesAdministrative
  crmDisablesFinancial
  crmDisablesUndefinedDealType
  crmDisabledDealType
  crmDisablesPartnership
  customReactiveChatsCount
  billetType
  billetToken1
  billetToken2
  billetUsername
  billetPassword
  billetSubdomain
  defaultDDD
  disabledDealType
  name
  parentId
  url
  whatsappNumber
  enableSendAnswerToPortalClient
  portalClientEmailButtons
  parent {
    id
    name
    url
  }
  address {
    street
    number
    complementary
    zip
    city
    state
    lat
    lon
  }
  chatWidgets {
    id
    name
    channel
    disableCrm
    disableRoulette
    disableResendEmail
    disableNotifications
    externalChatChannels {
      ...ExternalChatChannelFragment
    }
    useSchedule
  }
  users {
    id
    email
    name
    nickname
    role
    telegramUser
    telegramChatId
    clientsCount
    loginsReport {
      count 
      firstLogin
      lastLogin
    }
    salesGroupsReport {
      count
      salesGroupsNames
      inactiveSalesGroupsNames
    }
  }
  emailsToNotify {
    id
    email
    notificationSettings {
      administrativeBuy
      administrativeRent
      administrativeUndefinedDealType
      administrativeBadEvaluation
      angariationBuy
      angariationRent
      angariationUndefinedDealType
      angariationBadEvaluation
      financialBuy
      financialRent
      leadsUndefinedDealType
      leadsBadEvaluation
      leadsBuy
      leadsRent
      financialUndefinedDealType
      financialBadEvaluation

      # disabledDealType
      # administrativeListen
      # angariationListen
      # financialListen
      # justAngariationAdministrativeListen
    }
  }
  ...CustomReactiveChatQuestionsFragment
  ...CustomChatStepsFragment
  funnelSteps {
    ...FunnelStepFragment
  }
  openClientDetailsPage
  enableHarryLogin
  isRouletteActive
  distributeAllLeadsToFirstSalesman
  maxLeadsPerSalesman
  salesGroups {
    id
    name
    agencyChildId
    activePeriods
    activeWeekdays
    activeMonthDays
    activeTags
    forbiddenTags
    activeDDDs
    forbiddenDDDs
    disabledDealType
    isActive
    isDailyCheckin
    isPropertyRaiserPriorital
    propertyRaiserMustBeInRoulette
    defaultPropertyRaiserEmail

    leadsCount
    salespeopleEmails
    allowedEmails
  }
  isManagerBotActive
  managerBotInterval
  fileUploads {
    fileName
    fileNameOrig
    description
    type
    date
    size
  }
  updateFunnelStepCount
  updateCrmFunnelStep
  defaultFunnelStep
  enableSendObservationToClient
  customRoulettePeriods {
    ...CustomRoulettePeriodFragment
  }
  hideOtherSalesmanObservations
  observations {
    ...AgencyObservationFragment
  }
  departments {
    ...DepartmentFragment
  }
  customAuxTagTexts {
    ...CustomAuxTagTextFragment
  }
  cnpj
  contactPersonResponsible
}
${CUSTOM_CHAT_STEPS_FRAGMENT}
${CUSTOM_REACTIVE_CHAT_QUESTIONS_FRAGMENT}
${FUNNEL_STEP_FRAGMENT}
${EXTERNAL_CHAT_CHANNEL_FRAGMENT}
${CUSTOM_ROULETTE_PERIOD_FRAGMENT}
${AGENCY_OBSERVATION_FRAGMENT}
${DEPARTMENT_FRAGMENT}
${CUSTOM_AUX_TAG_TEXT_FRAGMENT}
`

export const REAL_ESTATE_AGENCY_DATABASE_FRAGMENT = gql`
fragment RealEstateAgencyDatabaseFragment on RealEstateAgencyDatabase {
  _id
  name
  realEstatesImportConfig {
    dataSource
    allowsNoValueRealEstates
    url
    encoding
    key
    agencyCodes
    state
    removeNonNumbersFromIdInAgency
    updateDisabled
    username
    password
    finality
    enterprise
  }
  realEstatesUrlRegexpConfig {
    idRegexpStr
    idTypeInUrl
    testUrl
    testId
  }
  realEstateCounts {
    total
    buy
    rent
  }
  lastRealEstateUpdateDate
}
`

export const REAL_ESTATE_AGENCY_PARENT_FRAGMENT = gql`
fragment RealEstateAgencyParentFragment on RealEstateAgencyParent {
  id
  name
  url
  idsMongo
  realEstateAgenciesDatabases {
    ...RealEstateAgencyDatabaseFragment
  }
  childs {
    id
    name
    crmEnabled
    crmSendMethod
    crmType
    crmToken
    defaultDDD
    disabledDealType
    openClientDetailsPage
    observations {
      ...AgencyObservationFragment
    }
  }
  observations {
    ...AgencyObservationFragment
  }
  templates {
    ...AgencyTemplateFragment
  }
}
${REAL_ESTATE_AGENCY_DATABASE_FRAGMENT}
${AGENCY_OBSERVATION_FRAGMENT}
${AGENCY_TEMPLATE_FRAGMENT}
`
