import { useMemo, useState, useEffect, ChangeEvent } from 'react'
import { ReloadOutlined } from '@ant-design/icons';
import { Tree, Skeleton, Button, Input, message, Tooltip } from "antd"
import flatten from 'lodash/flatten'
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import uniq from 'lodash/uniq'
import isNil from 'lodash/isNil'
import union from 'lodash/union'
import accentFold from 'utils/accentFold'
import highlightText from 'utils/highlightText'
import User from 'graphql/User/User';
import copyToClipboard from 'copy-to-clipboard'
import { DataNode } from 'rc-tree/lib/interface';

const { Search } = Input;

export interface TreeDataNode {
  key: string,
  title: string,
  children?: TreeDataNode[],
}

export function sortTreeDataByTitle(treeDataNodes: TreeDataNode[]) {
  const treeDataNodesCopy = [...treeDataNodes]
  treeDataNodesCopy.sort((a, b) => a.title.localeCompare(b.title))
  treeDataNodesCopy.forEach(node => {
    if (node.children) node.children = sortTreeDataByTitle(node.children)
  })
  return treeDataNodesCopy
}

export function userToTreeDataNodes(user: User): TreeDataNode[] {
  if (!user || !user.agent || !user.agent.agencies) return []

  const agencyParentsInUser = user.getAgencyParents()
  const agencyParents: TreeDataNode[] = agencyParentsInUser.map(a => ({ key: a.id, title: a.name, children: [] }))

  for (const agencyChild of user.agent.agencies) {
    const agencyParent = agencyParents.find(a => a.key === agencyChild.parent.id)
    agencyParent.children.push({
      key: agencyChild.id,
      title: agencyChild.name + ` (${agencyChild.chatWidgets.length})`,
      children: agencyChild.chatWidgets.map(c => ({
        key: c.id,
        title: c.name || "(canal padrão)"
      }))
    })
  }
  // Add children count
  agencyParents.forEach(a => a.title = a.title + ` (${a.children.length})`)

  // Sort everything by name
  return sortTreeDataByTitle(agencyParents)
}

/*const getParentKey = (key: string, treeDataNodes: TreeDataNode[]): string => {
  for (const node of treeDataNodes) {
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        return node.key
      } else {
        const key2 = getParentKey(key, node.children)
        if (key2) return key2
      }
    }
  }
  return null
}; */

const getAllParentKeys = (key: string, treeDataNodes: TreeDataNode[]): string[] => {
  for (const node of treeDataNodes) {
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        return [node.key]
      } else {
        const keys2 = getAllParentKeys(key, node.children)
        if (keys2) return [node.key, ...keys2]
      }
    }
  }
  return null
};

const getChildrenKeysFromNode = (key: string, treeDataNodes: TreeDataNode[]): string[] => {
  for (const node of treeDataNodes) {
    if (node.key === key) {
      if (node.children) return node.children.map(c => c.key)
      return []
    }
    else if (node.children) {
      const keys2 = getChildrenKeysFromNode(key, node.children)
      if (keys2) return keys2
    }
  }
  return null
};

const findMatchingKeys = (searchValue: string, treeDataNodes: TreeDataNode[]): string[] => {
  if (!searchValue) return []
  const searchValue2 = accentFold(searchValue.toLowerCase())
  return treeDataNodes.reduce<string[]>((result, cur) => {
    const title2 = accentFold(cur.title.toLowerCase())
    if (title2.indexOf(searchValue2) > -1)
      result.push(cur.key)
    if (cur.children) {
      const matchingKeysChildren = findMatchingKeys(searchValue, cur.children)
      matchingKeysChildren.forEach(matchingKey => result.push(matchingKey))
    }
    return result
  }, [])
}

const getDataNodes = (args: { treeDataNodes: TreeDataNode[], searchValue?: string, hiddenKeys?: string[] }): DataNode[] => {
  const { treeDataNodes, searchValue, hiddenKeys = [] } = args
  return treeDataNodes.map(node => {
    const title = highlightText(node.title, searchValue)

    const hidden = hiddenKeys.includes(node.key)
    const style = hidden ? { display: 'none' } : {}
    return {
      key: node.key,
      title,
      style,
      children: node.children && getDataNodes({ treeDataNodes: node.children, searchValue, hiddenKeys })
    }
    // return <TreeNode style={style} title={title} key={node.key}>
    //   {node.children && renderTreeNodes({ treeDataNodes: node.children, searchValue, hiddenKeys })}
    // </TreeNode>
  })
}


export interface Props {
  user: User
  onWidgetIdsCheckedChange?: (selectedWidgetIds: string[]) => void
  onWidgetIdSelected?: (selectedWidgetId: string | null) => void
  onAgencyIdsCheckedChange?: (selectedAgencyIds: string[]) => void
  onAgencyIdSelected?: (selectedAgencyId: string | null) => void
  onRefetchAgencies?: () => void
  checkedWidgetIds?: string[]
  hideCheckboxes?: boolean
  enableCopyToClipboard?: boolean
  idsToHide?: string[]
  /** hide agency parents that have no child nodes */
  hideEmptyParentNodes?: boolean
}

const WidgetIdsSelectionTree = (props: Props) => {
  const {
    user,
    checkedWidgetIds = [],
    hideCheckboxes = false,
    enableCopyToClipboard = true,
    onWidgetIdsCheckedChange,
    onWidgetIdSelected,
    onAgencyIdsCheckedChange,
    onAgencyIdSelected,
    onRefetchAgencies,
    idsToHide,
    hideEmptyParentNodes = false,
  } = props

  const allIds = useMemo(() => user ? user.getAllAgencyAndWidgetIds() : [], [user])
  const allWidgetIds = useMemo(() => user ? user.getAllWidgetIds() : [], [user])
  const allAgencyIds = useMemo(() => user ? user.getAllAgencyIds() : [], [user])

  const [searchValue, setSearchValue] = useState<string>(null)

  const [checkedKeys, setCheckedKeys] = useState<string[]>(checkedWidgetIds)
  // const allChecked = allIds.length === checkedKeys.length
  const someChecked = checkedKeys.length > 0
  const lotsOfAgencies = user && user.agent && user.agent.agencies && user.agent.agencies.length > 1;

  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  /** the keys that were hidden because didn't match the search criteria */
  const [hiddenKeysOfSearch, setHiddenKeysOfSearch] = useState<string[]>([])
  const [hiddenKeys, setHiddenKeys] = useState<string[]>(idsToHide || [])

  const treeDataNodes = useMemo(() => userToTreeDataNodes(user), [user])
  const treeNodesRendered = useMemo(() => getDataNodes({ treeDataNodes, searchValue, hiddenKeys }), [treeDataNodes, searchValue, hiddenKeys])


  const handleCheck = (checkedKeysNew: string[]) => {
    setCheckedKeys(checkedKeysNew)
    // console.log({ checkedKeysNew })
    const widgetIds = checkedKeysNew.filter(c => allWidgetIds.includes(c))
    onWidgetIdsCheckedChange && onWidgetIdsCheckedChange(widgetIds)

    const agencyIds = checkedKeysNew.filter(c => allAgencyIds.includes(c))
    onAgencyIdsCheckedChange && onAgencyIdsCheckedChange(agencyIds)
  }

  const handleSelect = (selectedKeys: string[]) => {
    if (onWidgetIdSelected) {
      const selectedWidgetIds = intersection(allWidgetIds, selectedKeys)
      const selectedWidgetId = selectedWidgetIds[0] || null
      onWidgetIdSelected(selectedWidgetId)
    }

    if (onAgencyIdSelected) {
      const selectedAgencyIds = intersection(allAgencyIds, selectedKeys)
      const selectedAgencyId = selectedAgencyIds[0] || null
      onAgencyIdSelected(selectedAgencyId)
    }

    const selectedKey = selectedKeys[0] || null
    if (enableCopyToClipboard && selectedKey) {
      copyToClipboard(selectedKey)
      message.info(`id '${selectedKey}' copiado para a área de transferência!`)
    }
  }


  const checkAll = (val: boolean) => {
    //console.log("changeAll("+val+")");
    const keys = val ? allIds : []
    handleCheck(keys)
  }

  /** update the hidden keys, taking into consideration the search criteria and the keys present in idsToHide */
  useEffect(() => {
    const hiddenKeysNew = union(hiddenKeysOfSearch, idsToHide || [])

    // Find all empty parent nodes and hide them
    const parentIdsToHide: string[] = hideEmptyParentNodes ?
      treeDataNodes
        .filter(parentNode => !parentNode.children || parentNode.children.every(child => hiddenKeysNew.includes(child.key)))
        .map(p => p.key)
      : []

    setHiddenKeys(union(hiddenKeysNew, parentIdsToHide))
  }, [hiddenKeysOfSearch, idsToHide, hideEmptyParentNodes, treeDataNodes])

  const handleSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.length > 2 ?
      e.target.value :
      null
    setSearchValue(value)
    const matchingKeys = findMatchingKeys(value, treeDataNodes)
    const expandedKeysNew = flatten(matchingKeys.map(key => getAllParentKeys(key, treeDataNodes)))
      .filter(cur => !isNil(cur))
    // console.log({ expandedKeysNew })

    const matchingKeysChildren = uniq(flatten(matchingKeys.map(key => getChildrenKeysFromNode(key, treeDataNodes))))

    const hiddenKeysNew = value ? difference(allIds, matchingKeys, expandedKeysNew, matchingKeysChildren) : []
    setExpandedKeys(expandedKeysNew)
    setHiddenKeysOfSearch(hiddenKeysNew)
  };

  if (!(user && user.agent && user.agent.agencies && user.agent.agencies))
    return <div>Usuário não possui vinculos ativos</div>

  return <>
    {user ? <>
      {lotsOfAgencies &&
        <>
          {!hideCheckboxes &&
            <Button onClick={(e) => checkAll(!someChecked)} style={{ marginBottom: "1em" }}>
              {(someChecked ? "Desmarcar" : "Selecionar") + " todos"}
            </Button>
          }
          {onRefetchAgencies &&
            <Tooltip title='Recarregar agências'>
              <Button onClick={onRefetchAgencies} style={{ marginBottom: "1em", marginLeft: "1em" }} shape='circle' icon={<ReloadOutlined />} />
            </Tooltip>
          }
          <Search style={{ marginBottom: 8 }} placeholder="Buscar" onChange={handleSearchFieldChange} allowClear />
        </>
      }

      <Tree
        checkable={!hideCheckboxes}
        checkedKeys={checkedKeys}
        onCheck={checkedKeys => handleCheck(checkedKeys as string[])}
        expandedKeys={expandedKeys}
        onExpand={(e) => setExpandedKeys(e as string[])}
        onSelect={selectedKeys => handleSelect(selectedKeys as string[])}
        treeData={treeNodesRendered}
      >
        {/* {treeNodesRendered} */}
      </Tree>
    </>
      :
      <Skeleton active />
    }
  </>;
}

export default WidgetIdsSelectionTree;