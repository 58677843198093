import { Spin } from 'antd'

const PageLoadingPage = () => {
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }}>
    <Spin />
  </div>
};

export default PageLoadingPage;
