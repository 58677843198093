import { useState } from "react";
import { Alert } from "antd";
import { FrownOutlined, WarningOutlined } from "@ant-design/icons";

const NetworkErrorPage = () => {
  // const delay = 6000; //ms
  const [tries, setTries] = useState(3);
  const [timer, setTimer] = useState(10);

  const checkNavigatorOnline = () => {
    if (timer <= 0) {
      setTimer(10);
      // reload page when online
      if (navigator.onLine) document.location.reload();
      else setTries(tries - 1);
    } else setTimer(timer - 1);
  };

  if (tries > 0) window.setTimeout(checkNavigatorOnline, 1000);

  return (
    <>
      {tries <= 0 ? (
        <Alert
          type="error"
          icon={<FrownOutlined />}
          showIcon
          message="Erro"
          description={
            <div>
              <p>Um erro ocorreu ao carregar a página.</p>
              <p>Verifique sua conexão e aperte F5 para atualizar a página.</p>
            </div>
          }
        />
      ) : (
          <Alert
            type="warning"
            message="Aviso:"
            icon={<WarningOutlined />}
            showIcon
            description={
              <div>
                <p>Um erro ocorreu ao carregar a página.</p>
                <p>
                  Será realizada uma nova tentativa de conexão em {timer}{" "}
                segundos. Ou aperte F5 para atualizar a página.
                </p>
                {/* <p>
                <b>Tentativas restantes: {tries}</b>
              </p> */}
              </div>
            }
          />
        )}
    </>
  );
};

export default NetworkErrorPage;
