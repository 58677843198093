import { CheckSquareOutlined, CloseSquareOutlined, HomeOutlined, LoadingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Grid, MenuProps, Modal, Tooltip } from 'antd';
import User from 'graphql/User/User';
import { useRouletteCheckinMutation, useRouletteCheckoutMutation } from 'graphql/User/UserMutations';
import { useRouletteChecksQuery } from 'graphql/User/UserQueries';
import { openNotification } from 'utils/openNotification';
import { RouletteStatus } from 'graphql/apollo-graphql-generated/globalTypes';
import Bugsnag from '@bugsnag/js';
import { useState } from 'react';

const confirm = Modal.confirm


export interface Props {
  onLogout: () => void
  user: User
  onOpenAgenciesSelection: () => void
}

export default function UserDropdown(props: Props) {
  const { onLogout, onOpenAgenciesSelection, user } = props;
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [checkinLoading, setCheckinLoading] = useState<boolean>(false);
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);
  const [refetchLoading, setRefetchLoading] = useState<boolean>(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { data, loading, refetch } = useRouletteChecksQuery({
    variables: { id: user.id },
    pollInterval: 5 * 60 * 1000, // 5 minutes,
    onError(error) {
      Bugsnag.notify(error, (event) => event.setUser(user.id, user.email, user.name))
    },
  });

  const refetchRouletteStatus = async () => {
    setRefetchLoading(true);
    await refetch();
    setRefetchLoading(false);
  }

  const rouletteChecks = data?.rouletteChecks;
  const rouletteStatus = rouletteChecks?.status;

  const disableRouletteCheckinMenuItem: boolean = rouletteStatus !== RouletteStatus.NOT_CHECKED_IN
  const disableRouletteCheckoutMenuItem: boolean = rouletteStatus !== RouletteStatus.CHECKED_IN

  const isCheckinAllowed: boolean = rouletteStatus === RouletteStatus.NOT_CHECKED_IN;
  const isCheckoutAllowed: boolean = rouletteStatus === RouletteStatus.CHECKED_IN;
  const checkedIn = rouletteStatus === RouletteStatus.CHECKED_IN;
  const checkedOut = rouletteStatus === RouletteStatus.NOT_CHECKED_IN;

  const [rouletteCheckinMutation] = useRouletteCheckinMutation();
  const runCheckinMutation = async () => {
    setCheckinLoading(true);
    try {
      const result = await rouletteCheckinMutation({ variables: { id: user?.id } });
      if (result.data?.rouletteCheckin) {
        openNotification("Checkin da Roleta de atendimento", result.data.rouletteCheckin);
      } else {
        openNotification("Erro!", "A requisição de checkin falhou. Contate o administrador do sistema");
      }
      await refetch();
    } catch (e: any) {
      Bugsnag.notify(e, (event) => event.setUser(user.id, user.email, user.name));
      openNotification("Erro!", e.message);
    }
    setCheckinLoading(false);
  }

  const [rouletteCheckoutMutation] = useRouletteCheckoutMutation();
  const runCheckoutMutation = async () => {
    setCheckoutLoading(true);
    try {
      const result = await rouletteCheckoutMutation({ variables: { id: user?.id } });
      if (result.data?.rouletteCheckout) {
        openNotification("Checkout da Roleta de atendimento", result.data.rouletteCheckout);
      } else {
        openNotification("Erro!", "A requisição de checkout falhou. Contate o administrador do sistema");
      }
      await refetch();
    } catch (e: any) {
      Bugsnag.notify(e, (event) => event.setUser(user.id, user.email, user.name));
      openNotification("Erro!", e.message);
    }
    setCheckoutLoading(false);
  }

  const getTooltipText = (caller: "checkin" | "checkout") => {
    if (caller === 'checkin') {
      if (isCheckinAllowed) return "Checkin na roleta de atendimento";
      if (checkedIn) return "Seu checkin na roleta de atendimento já foi realizado";
      return `Checkin na roleta de atendimento não permitido. ${rouletteChecks?.message}`;
    } else {
      if (isCheckoutAllowed) return "Checkout na roleta de atendimento";
      if (checkedOut) return "Seu checkout na roleta de atendimento já foi realizado";
      return `Checkout na roleta de atendimento não permitido. ${rouletteChecks?.message}`;
    }
  };

  const getIcon = (caller: "checkin" | "checkout") => {
    if (checkinLoading || checkoutLoading || refetchLoading) return <LoadingOutlined />;

    if (caller === 'checkin') {
      if (isCheckinAllowed) return <CheckSquareOutlined style={{ color: '#00cc00' }} />;
      if (checkedIn) return <CheckSquareOutlined disabled />;
      return <CheckSquareOutlined />;
    } else {
      if (isCheckoutAllowed) return <CloseSquareOutlined style={{ color: 'red' }} />;
      if (checkedOut) return <CloseSquareOutlined disabled />;
      return <CloseSquareOutlined />;
    }
  }

  const userFirstName = user && user.name ? user.name.split(' ')[0] : '-'

  const items: MenuProps['items'] = [
    {
      key: 'userName',
      label: user ? user.name : 'Usuário inválido',
      icon: <UserOutlined />,
      disabled: true,
      onClick: () => onOpenAgenciesSelection()
    },
    {
      key: 'agenciesSelect',
      label: user ? "Loja/Canais" : 'Usuário inválido',
      icon: <HomeOutlined />,
      onClick: () => onOpenAgenciesSelection()
    },
    {
      key: 'rouletteCheckin',
      label: <Tooltip title={getTooltipText('checkin')}>{user ? "Checkin" : 'Usuário inválido'}</Tooltip>,
      icon: getIcon('checkin'),
      disabled: loading || disableRouletteCheckinMenuItem || checkinLoading || checkoutLoading || refetchLoading,
      onClick: () => runCheckinMutation(),
    },
    {
      key: 'rouletteCheckout',
      label: <Tooltip title={getTooltipText('checkout')}>{user ? "Checkout" : 'Usuário inválido'}</Tooltip>,
      icon: getIcon('checkout'),
      disabled: loading || disableRouletteCheckoutMenuItem || checkinLoading || checkoutLoading || refetchLoading,
      onClick: () => runCheckoutMutation(),
    },
    {
      key: 'logout',
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        confirm({
          title: 'Deseja realmente fazer logout?',
          okText: 'Sim',
          cancelText: 'Não',
          onOk: onLogout
        })
      }
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      open={dropdownOpen}
      onOpenChange={open => {
        setDropdownOpen(open)
        if (open) refetchRouletteStatus()
      }}
    >
      <span>
        {screens?.lg ? <UserOutlined style={{ width: '24px', height: '24px' }} /> : null}
        <span>{userFirstName}</span>
      </span>
    </Dropdown>
  );
}