import gql from 'graphql-tag'
import { CUSTOM_AUX_TAG_TEXT_FRAGMENT, FUNNEL_STEP_FRAGMENT } from 'graphql/Agency/AgencyFragments'
import { EXTERNAL_CHAT_CHANNEL_FRAGMENT } from 'graphql/Chats/ExternalChatChannelFragments'

export const USER_AGENCY_FRAGMENT = gql`
fragment UserAgencyFragment on RealEstateAgencyChild {
  id
  name
  parent {
    id
    idsMongo
    realEstateAgenciesDatabases {
      _id
      name
    }
    name
    url
  }
  chatWidgets {
    id
    name
    channel
    externalChatChannels {
      ...ExternalChatChannelFragment
    }
  }
  funnelSteps {
    ...FunnelStepFragment
  }
  crmEnabled
  crmSendMethod
  disabledDealType
  updateFunnelStepCount
  updateCrmFunnelStep
  defaultFunnelStep
  enableSendObservationToClient
  hideOtherSalesmanObservations
  departments {
    id
    name
    tags
  }
  customAuxTagTexts {
    ...CustomAuxTagTextFragment
  }
}
${EXTERNAL_CHAT_CHANNEL_FRAGMENT}
${FUNNEL_STEP_FRAGMENT}
${CUSTOM_AUX_TAG_TEXT_FRAGMENT}
`

export const USER_FRAGMENT = gql`

fragment notificationSettingsFragment on NotificationSettings {
  administrativeBuy
  administrativeRent
  administrativeUndefinedDealType
  administrativeBadEvaluation
  angariationBuy
  angariationRent
  angariationUndefinedDealType
  angariationBadEvaluation
  financialBuy
  financialRent
  leadsUndefinedDealType
  leadsBadEvaluation
  leadsBuy
  leadsRent
  financialUndefinedDealType
  financialBadEvaluation

  clientChangedAgency
}

fragment UserFragment on User {
  id
  email
  phone
  name
  nickname
  role
  clientTagsPermissions

  telegramUser 
  telegramChatId
  telegramNotificationSettings {
    ...notificationSettingsFragment
  }
  whatsappNotificationSettings {
    ...notificationSettingsFragment
  }
  whatsappPhoneNumber
  
  clientsCount
  disabled
  loginsReport {
    count
    firstLogin
    lastLogin
  }
  salesGroupsReport {
    count
    salesGroupsNames
    inactiveSalesGroupsNames
  }

  externalChatChannelsPermissions {
    id
    externalChatChannel {
      id
      name
      disabled
      idInChannel
      type  
    }
  }
  departments {
    id
    name
    tags
  }
  si9Code
  rouletteEvents {
    id
    createdAt
    type
    isDashboardEvent
    salesGroupsNames
    message
    userId
    userName
    userEmail
    clientId
    period
    weekDay
  }
}
`

export const USER_WITH_ALL_AGENCIES_FRAGMENT = gql`
fragment UserWithAllAgenciesFragment on User {
  ...UserFragment
  agent {
    agencies {
      ...UserAgencyFragment
    }
    pages
  }
}
${USER_FRAGMENT}
${USER_AGENCY_FRAGMENT}
`

export const USER_WITH_AGENCY_CHILDREN_FRAGMENT = gql`
fragment UserWithAgencyChildrenFragment on User {
  ...UserFragment
  loginsReport {
    count
    firstLogin
    lastLogin
  }
  salesGroupsReport {
    count
    salesGroupsNames
    inactiveSalesGroupsNames
  }
  agent {
    agencies {
      id
      name
    }
  }
}
${USER_FRAGMENT}
`
