import { ReloadOutlined } from "@ant-design/icons"
import { ApolloQueryResult } from "@apollo/client"
import { Button, Modal, Table, Tooltip } from "antd"
import { ColumnProps } from "antd/es/table"
import dayjs from "dayjs"
import { OnlineSalesmansCountQuery, OnlineSalesmansCountQueryVariables, OnlineSalesmansCountQuery_onlineSalesmansCount_salesmans } from "graphql/Chats/apollo-graphql-generated/OnlineSalesmansCountQuery"
import { useState } from "react"

type OnlineSalesmansModalProps = {
  open: boolean
  onCancel: () => void
  salesmans: OnlineSalesmansCountQuery_onlineSalesmansCount_salesmans[]
  refetch: (variables?: Partial<OnlineSalesmansCountQueryVariables>) => Promise<ApolloQueryResult<OnlineSalesmansCountQuery>>
}
export const OnlineSalesmansModal = (props: OnlineSalesmansModalProps) => {
  const { open, onCancel, salesmans, refetch } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const columns: ColumnProps<OnlineSalesmansCountQuery_onlineSalesmansCount_salesmans>[] = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
      render: (name, record) => <Tooltip title={record.email}>{name}</Tooltip>,
      width: 170,
    },
    {
      title: 'Grupos',
      key: 'groups',
      dataIndex: 'groups',
      render: (groups: string[]) => groups?.join(', ')
    },
    {
      title: 'Próximo a receber um lead em',
      key: 'nextToReceiveALead',
      dataIndex: 'nextToReceiveALead',
      render: (nextToReceiveALead: string[]) => nextToReceiveALead?.join(', ')
    },
    {
      title: 'Data',
      key: 'checkinEventCreatedAt',
      dataIndex: 'checkinEventCreatedAt',
      render: (date) => dayjs(date).format('DD/MM/YYYY HH:mm'),
      width: 130,
    },
  ]

  return <Modal
    title={'Corretores online'}
    onCancel={onCancel}
    open={open}
    width={700}
    footer={<Button
      loading={loading}
      icon={<ReloadOutlined />}
      type="primary"
      onClick={async () => {
        setLoading(true);
        await refetch();
        setLoading(false)
      }}
    >
      Atualizar dados
    </Button>}
  >
    <Table
      loading={loading}
      dataSource={salesmans}
      columns={columns}
      size="small"
      scroll={{ x: 650 }}
    />
  </Modal>
}