import { CloseOutlined } from '@ant-design/icons';
import { Layout } from "antd";
import { createGlobalStyle } from 'styled-components';
import WidgetIdsSelectionTree from '../../../components/WidgetIdsSelectionTree';
import User from 'graphql/User/User';

const { Sider } = Layout

const GlobalStyle = createGlobalStyle`
/* 
  .ant-layout-sider-zero-width-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    background: #2d517354;
    color: black;
    height: 64px;
    z-index: 1;

    :hover {
      background: #2d517399;
    }
  }
 */
`

export interface Props {
  onClose: (e: any) => void
  user: User
  collapsed: boolean
  onWidgetIdsSelectionChange: (selectedWidgetIds: string[]) => void
  onRefetchAgencies?: () => void
  selectedWidgetIds: string[]
}

const DashboardRightSider = (props: Props) => {
  const { collapsed, onClose, user, selectedWidgetIds, onWidgetIdsSelectionChange, onRefetchAgencies } = props

  return <>
    <GlobalStyle />
    <Sider
      collapsed={collapsed}
      style={{ background: '#fff', overflowY: 'auto' }}
      collapsedWidth={0}
      width={256}
    >
      <div
        style={{
          padding: '16px 24px',
          borderBottom: '1px solid #f0f0f0'
        }}
      >
        <div
          style={{
            color: 'rgba(0,0,0,.85)',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
          }}
        >
          Selecionar filiais
        </div>
        <button
          aria-label="Close"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 10,
            display: 'block',
            padding: '20px',
            color: 'rgba(0,0,0,.45)',
            fontWeight: 700,
            fontSize: '16px',
            fontStyle: 'normal',
            lineHeight: 1,
            textAlign: 'center',
            textTransform: 'none',
            textDecoration: 'none',
            background: 'transparent',
            border: 0,
            outline: 0,
            cursor: 'pointer',
            transition: 'color .3s',
            textRendering: 'auto',
          }}
        >
          <CloseOutlined />
        </button>
      </div>

      <div
        style={{
          padding: '24px',
          overflow: 'auto',
          fontSize: '14px',
          lineHeight: '1.5715',
          wordWrap: 'break-word'
        }}
      >
        <WidgetIdsSelectionTree
          user={user}
          onWidgetIdsCheckedChange={onWidgetIdsSelectionChange}
          checkedWidgetIds={selectedWidgetIds}
          enableCopyToClipboard={user && user.role === 'FIREFLY_ADMIN'}
          onRefetchAgencies={onRefetchAgencies}
        />
      </div>

    </Sider>
  </>;
}

export default DashboardRightSider;