import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from '@apollo/client';
import { OnlineClientsCountQuery, OnlineClientsCountQueryVariables } from './apollo-graphql-generated/OnlineClientsCountQuery';
import { OnlineClientsCountPerMinuteQuery, OnlineClientsCountPerMinuteQueryVariables } from './apollo-graphql-generated/OnlineClientsCountPerMinuteQuery';
import { OnlineSalesmansCountQuery, OnlineSalesmansCountQueryVariables } from './apollo-graphql-generated/OnlineSalesmansCountQuery';

export const ONLINE_CLIENTS_COUNT_QUERY = gql`
query OnlineClientsCountQuery
($widgetIds: [String!]) {
  onlineClientsCount (
    widgetIds: $widgetIds
  )
}`

export const ONLINE_CLIENTS_COUNT_PER_MINUTE_QUERY = gql`
query OnlineClientsCountPerMinuteQuery ($widgetIds: [String!], $startDate: DateTime!) {
  onlineClientsCountPerMinute (widgetIds: $widgetIds, startDate: $startDate) {
    date
    countConnected
    countInteracting
  }
}`

export const ONLINE_SALEMANS_COUNT_QUERY = gql`
query OnlineSalesmansCountQuery($widgetIds: [String!]) {
  onlineSalesmansCount(widgetIds: $widgetIds) {
    count
    salesmans {
      email
      name
      groups
      checkinEventCreatedAt
      nextToReceiveALead
    }
  }
}`

//
// Custom Hooks
//

export function useOnlineClientsCountQuery(options?: QueryHookOptions<OnlineClientsCountQuery, OnlineClientsCountQueryVariables>) {
  return useQuery<OnlineClientsCountQuery, OnlineClientsCountQueryVariables>(ONLINE_CLIENTS_COUNT_QUERY, options)
}

export function useOnlineClientsCountPerMinuteQuery(options?: QueryHookOptions<OnlineClientsCountPerMinuteQuery, OnlineClientsCountPerMinuteQueryVariables>) {
  return useQuery<OnlineClientsCountPerMinuteQuery, OnlineClientsCountPerMinuteQueryVariables>(ONLINE_CLIENTS_COUNT_PER_MINUTE_QUERY, options)
}

export function useOnlineSalesmansCountQuery(options?: QueryHookOptions<OnlineSalesmansCountQuery, OnlineSalesmansCountQueryVariables>) {
  return useQuery<OnlineSalesmansCountQuery, OnlineSalesmansCountQueryVariables>(ONLINE_SALEMANS_COUNT_QUERY, options)
}