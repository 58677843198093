import Bugsnag from "@bugsnag/js";
import {
  ChatClientTagType,
  DateRangeFilter,
} from "graphql/apollo-graphql-generated/globalTypes";
import { ChatSelectButtonsOrderBy } from "pages/Chats/ChatOnlinePage/components/ChatSelectButtons";

/** current version for the user settings, used to check for compatibility of saved settings */
export const USER_SETTINGS_CURRENT_VERSION = 1;

export interface UserSettingsInterface {
  /** current version for the user settings, used to check for compatibility of saved settings */
  version: number;
  chatOnlineSettings: ChatOnlineSettings;
  chatHistoryCardSettings: ChatHistoryCardSettings;
}

export interface ChatOnlineSettings {
  chatFilters: ChatOnlineSettingsFilters;
  enableNotifications: boolean;
  filterNotifications: boolean;
  chatsToDisableUserName: string[];
}

export interface ChatOnlineSettingsFilters {
  chatsOrderBy: ChatSelectButtonsOrderBy;
  showBotMessages: boolean;
  showNotInteractedClients: boolean;
  /** show users shared with tags in chat select buttons */
  showUsersSharedWithTags: boolean;
  /** only show clients from the users with specified ids */
  usersSharedWithIdFilter?: string[];
  /** only show clients that are not shared with users */
  showWithoutSharedWith?: boolean;
  tagsFilter?: ChatClientTagType[];
  funnelStepIdFilter?: string;
}

export const chatOnlineSettingsFiltersDefault: ChatOnlineSettingsFilters = {
  chatsOrderBy: "DATE_DESC",
  showBotMessages: true,
  showNotInteractedClients: false,
  showUsersSharedWithTags: true,
  usersSharedWithIdFilter: [],
  showWithoutSharedWith: false,
  tagsFilter: [],
  funnelStepIdFilter: null,
};

export const selectedSpreadSheetColumnsDefault: ClientsSpreadSheetColumns = {
  id: false,
  createdAtDate: true,
  createdAtHour: true,
  updatedAtDate: true,
  updatedAtHour: true,
  agency: true,
  agents: true,
  name: true,
  phone: true,
  email: true,
  tags: false,
  origin: true,
  registrationChannel: true,
  dealType: false,
  funnelStep: true,
  preferredRealEstate: true,
  url: true,
}

export type ClientsSpreadSheetColumns = {
  id: boolean
  createdAtDate: boolean
  createdAtHour: boolean
  updatedAtDate: boolean
  updatedAtHour: boolean
  agency: boolean
  agents: boolean
  name: boolean
  phone: boolean
  email: boolean
  tags: boolean
  origin: boolean
  registrationChannel: boolean
  dealType: boolean
  funnelStep: boolean
  preferredRealEstate: boolean
  url: boolean
}

export interface ChatHistoryCardSettings {
  selectedDateRangeDays?: number;
  showNoLeadClients?: boolean;
  dateRangeFilter?: DateRangeFilter;
  selectedSpreadSheetColumns?: ClientsSpreadSheetColumns;
}

export const defaultValues: Readonly<UserSettingsInterface> = {
  version: USER_SETTINGS_CURRENT_VERSION,
  chatOnlineSettings: {
    chatFilters: chatOnlineSettingsFiltersDefault,
    enableNotifications: false,
    filterNotifications: false,
    chatsToDisableUserName: [],
  },
  chatHistoryCardSettings: {
    selectedSpreadSheetColumns: selectedSpreadSheetColumnsDefault,
  },
};

export class UserSettings implements UserSettingsInterface {
  static readonly LOCAL_STORAGE_KEY = "firefly-dashboard-user-settings";

  public version: number;
  public chatOnlineSettings: ChatOnlineSettings;
  public chatHistoryCardSettings: ChatHistoryCardSettings;
  public selectedSpreadSheetColumns: ClientsSpreadSheetColumns;

  constructor(userSettingsInterface: UserSettingsInterface) {
    Object.assign(this, userSettingsInterface);
  }

  public static fromLocalStorage = (): UserSettings => {
    const res = window.localStorage.getItem(UserSettings.LOCAL_STORAGE_KEY);
    if (!res) return null;

    try {
      const userSettingsInterface: UserSettingsInterface = JSON.parse(res);

      if (typeof userSettingsInterface !== "object") return null;

      return new UserSettings(userSettingsInterface);
    } catch (e: any) {
      Bugsnag.notify(e);
      console.log(
        "[UserSettings] Error parsing UserSettings local storage object"
      );
      return null;
    }
  };

  public static fromDefaultValues = (): UserSettings => {
    return new UserSettings(defaultValues);
  };

  protected saveToLocalStorage = () => {
    window.localStorage.setItem(
      UserSettings.LOCAL_STORAGE_KEY,
      JSON.stringify(this)
    );
  };

  /** update chat online settings, changing only the existing values in the 'values' param */
  public updateChatOnlineSettings = (values: Partial<ChatOnlineSettings>) => {
    // console.log('updateChatOnlineSettings', { values })
    this.chatOnlineSettings = { ...this.chatOnlineSettings, ...values };
    this.saveToLocalStorage();
  };

  /** update chat history card settings, changing only the existing values in the 'values' param */
  public updateChatHistoryCardSettings = (
    values: Partial<ChatHistoryCardSettings>
  ) => {
    this.chatHistoryCardSettings = {
      ...this.chatHistoryCardSettings,
      ...values,
    };
    this.saveToLocalStorage();
  };
}
