import { CSSProperties } from 'react'
import accentFold from './accentFold';

export default function highlightText(text: string, searchValue: string, options?: {
  style?: CSSProperties,
  highlightStyle?: CSSProperties,
}): JSX.Element {
  const { style = undefined, highlightStyle = {} } = options || {}
  text = text && typeof text === 'string' ? text : ''

  const text2 = accentFold(text.toLowerCase())
  const searchValue2 = searchValue && accentFold(searchValue.toLowerCase()).trim()
  const index = searchValue && text2.indexOf(searchValue2)
  const beforeStr = searchValue && text.substr(0, index)
  const middleStr = searchValue && text.substr(index, searchValue.length)
  const afterStr = searchValue && text.substr(index + searchValue.length)

  return searchValue && index > -1 ?
    <span style={style}>
      {beforeStr}
      <span style={{ color: '#f50', ...highlightStyle }}>{middleStr}</span>
      {afterStr}
    </span>
    :
    <span style={style}>{text}</span>
}