import gql from 'graphql-tag'
import { useQuery, QueryHookOptions } from '@apollo/client';
import { CheckPasswordResetCodeQuery, CheckPasswordResetCodeQueryVariables } from './apollo-graphql-generated/CheckPasswordResetCodeQuery';
import { WidgetUsersQuery, WidgetUsersQueryVariables } from './apollo-graphql-generated/WidgetUsersQuery';
import { WidgetsUsersQuery, WidgetsUsersQueryVariables } from './apollo-graphql-generated/WidgetsUsersQuery';
import { UserQuery, UserQueryVariables } from './apollo-graphql-generated/UserQuery';
import { UserMinimalQuery, UserMinimalQueryVariables } from './apollo-graphql-generated/UserMinimalQuery';
import { UsersMinimalQuery, UsersMinimalQueryVariables } from './apollo-graphql-generated/UsersMinimalQuery';
import { UserRouletteStatusQuery, UserRouletteStatusQueryVariables } from './apollo-graphql-generated/UserRouletteStatusQuery';
import { UsersByRoleQuery, UsersByRoleQueryVariables } from './apollo-graphql-generated/UsersByRoleQuery';
import { UsersByRoleMinimalQuery, UsersByRoleMinimalQueryVariables } from './apollo-graphql-generated/UsersByRoleMinimalQuery';
import { UsersByRoleStatsQuery } from './apollo-graphql-generated/UsersByRoleStatsQuery';
import { ClientSalesmanMessagesQuery, ClientSalesmanMessagesQueryVariables } from './apollo-graphql-generated/ClientSalesmanMessagesQuery';
import { ClientSchedulesQuery, ClientSchedulesQueryVariables } from './apollo-graphql-generated/ClientSchedulesQuery'
import { USER_WITH_ALL_AGENCIES_FRAGMENT } from './UserFragments';
import { RouletteEventsQuery, RouletteEventsQueryVariables } from './apollo-graphql-generated/RouletteEventsQuery';
import { RouletteChecksQuery, RouletteChecksQueryVariables } from './apollo-graphql-generated/RouletteChecksQuery';

export const CHECK_PASSWORD_RESET_CODE_QUERY = gql`
query CheckPasswordResetCodeQuery ($code: String!) {
  checkPasswordResetCode (code: $code) {
    code
    email
  }
} `;

export const WIDGET_USERS_QUERY = gql`
query WidgetUsersQuery ($widgetId: String!, $roles: [UserRole!]!) {
   widgetUsers (widgetId: $widgetId, roles: $roles) {
     id
     email
     name
     role
     disabled
   }
 }`

export const WIDGETS_USERS_QUERY = gql`
query WidgetsUsersQuery ($widgetIds: [String!], $roles: [UserRole!]!) {
   widgetsUsers (widgetIds: $widgetIds, roles: $roles) {
     id
     email
     name
     role
     disabled
   }
 }`

export const USER_QUERY = gql`
query UserQuery ($id: String!) {
  user (id: $id) {
    ...UserWithAllAgenciesFragment
  }
}
${USER_WITH_ALL_AGENCIES_FRAGMENT}
`

export const USER_MINIMAL_QUERY = gql`
query UserMinimalQuery ($id: String!) {
  userMinimal (id: $id) {
    id
    name
    email
    phone
    role
  }
}
`

export const USERS_MINIMAL_QUERY = gql`
query UsersMinimalQuery ($ids: [String!]!) {
  usersMinimal (ids: $ids) {
    id
    name
    email
    phone
    role
  }
}
`

export const USER_ROULETTE_STATUS_QUERY = gql`
query UserRouletteStatusQuery ($id: String!) {
  user (id: $id) {
    id
    name
    rouletteStatus
  }
}
`


export const USERS_BY_ROLE_QUERY = gql`
query UsersByRoleQuery ($args: UsersByRoleInput!) {
  usersByRole (args: $args) {
    users {
      id
      name
      nickname
      email
      phone
      telegramUser
      telegramChatId
      role
      disabled
      loginsReport {
        count
        firstLogin
        lastLogin
      }
      salesGroupsReport {
        count
        salesGroupsNames
        inactiveSalesGroupsNames
      }
      agent {
        agencies {
          id
          name
        }
      }
    }
    totalOfUsers
  }
}
`

export const USERS_BY_ROLE_MINIMAL_QUERY = gql`
query UsersByRoleMinimalQuery ($args: UsersByRoleInput!) {
  usersByRoleMinimal (args: $args) {
    users {
      id
      name
      email
      phone
      role
      disabled
      # agent {
      #   agencies {
      #     id
      #     name
      #   }
      # }
    }
    # totalOfUsers
  }
}
`

export const USERS_BY_ROLE_STATS_QUERY = gql`
query UsersByRoleStatsQuery {
  usersByRoleStats {
    totalUsers {
      name
      cont
    }
    telegramUsers {
      name
      cont
    }
    neverAccessed {
      name
      cont
    }
    recentAccess
  }
}
`

export const CLIENT_SCHEDULES_QUERY = gql`
query ClientSchedulesQuery ($startDate: DateTime!, $endDate: DateTime!, $widgetIds: [String!]) {
  clientSchedules (startDate: $startDate, endDate: $endDate, widgetIds: $widgetIds) {
    date
    realEstates
    text
    client
    messageDate
    name
    phone
    email
  }
}
`

export const CLIENT_SALESMAN_MESSAGES_QUERY = gql`
query ClientSalesmanMessagesQuery ($startDate: DateTime!, $endDate: DateTime!, $widgetIds: [String!]) {
  clientSalesmanMessages (startDate: $startDate, endDate: $endDate, widgetIds: $widgetIds) {
    text
    client
    botResponse
    date
  }
}
`

export const ROULETTE_EVENTS_QUERY = gql`
query RouletteEventsQuery ($args: RouletteEventsInput!) {
  rouletteEvents (args: $args) {
    events {
      id
      createdAt
      type
      isDashboardEvent
      salesGroupsNames
      message
      userId
      userName
      userEmail
      clientId
      period
      weekDay
    }
    currentPage
    totalOfEvents
    totalOfPages
    allUniqSalesGroupsNames
  }
}
`

export const ROULETTE_CHECKS_QUERY = gql`
query RouletteChecksQuery ($id: String!) {
  rouletteChecks (id: $id) {
    success
    message
    status
  }
}
`

//
// Custom Hooks
//

export function useClientSalesmanMessagesQuery(options?: QueryHookOptions<ClientSalesmanMessagesQuery, ClientSalesmanMessagesQueryVariables>) {
  return useQuery<ClientSalesmanMessagesQuery, ClientSalesmanMessagesQueryVariables>(CLIENT_SALESMAN_MESSAGES_QUERY, options)
}

export function useClientSchedulesQuery(options?: QueryHookOptions<ClientSchedulesQuery, ClientSchedulesQueryVariables>) {
  return useQuery<ClientSchedulesQuery, ClientSchedulesQueryVariables>(CLIENT_SCHEDULES_QUERY, options)
}

export function useUsersByRoleQuery(options?: QueryHookOptions<UsersByRoleQuery, UsersByRoleQueryVariables>) {
  return useQuery<UsersByRoleQuery, UsersByRoleQueryVariables>(USERS_BY_ROLE_QUERY, options)
}

export function useUsersByRoleMinimalQuery(options?: QueryHookOptions<UsersByRoleMinimalQuery, UsersByRoleMinimalQueryVariables>) {
  return useQuery<UsersByRoleMinimalQuery, UsersByRoleMinimalQueryVariables>(USERS_BY_ROLE_MINIMAL_QUERY, options)
}

export function useUsersByRoleStatsQuery(options?: QueryHookOptions<UsersByRoleStatsQuery>) {
  return useQuery<UsersByRoleStatsQuery>(USERS_BY_ROLE_STATS_QUERY, options)
}

export function useUserQuery(options?: QueryHookOptions<UserQuery, UserQueryVariables>) {
  return useQuery<UserQuery, UserQueryVariables>(USER_QUERY, options)
}

export function useUserMinimalQuery(options?: QueryHookOptions<UserMinimalQuery, UserMinimalQueryVariables>) {
  return useQuery<UserMinimalQuery, UserMinimalQueryVariables>(USER_MINIMAL_QUERY, options)
}

export function useUsersMinimalQuery(options?: QueryHookOptions<UsersMinimalQuery, UsersMinimalQueryVariables>) {
  return useQuery<UsersMinimalQuery, UsersMinimalQueryVariables>(USERS_MINIMAL_QUERY, options)
}

export function useUserRouletteStatusQuery(options?: QueryHookOptions<UserRouletteStatusQuery, UserRouletteStatusQueryVariables>) {
  return useQuery<UserRouletteStatusQuery, UserRouletteStatusQueryVariables>(USER_ROULETTE_STATUS_QUERY, options)
}

export function useCheckPasswordResetCodeQuery(options?: QueryHookOptions<CheckPasswordResetCodeQuery, CheckPasswordResetCodeQueryVariables>) {
  return useQuery<CheckPasswordResetCodeQuery, CheckPasswordResetCodeQueryVariables>(CHECK_PASSWORD_RESET_CODE_QUERY, options)
}

export function useWidgetUsersQuery(options?: QueryHookOptions<WidgetUsersQuery, WidgetUsersQueryVariables>) {
  return useQuery<WidgetUsersQuery, WidgetUsersQueryVariables>(WIDGET_USERS_QUERY, options)
}

export function useWidgetsUsersQuery(options?: QueryHookOptions<WidgetsUsersQuery, WidgetsUsersQueryVariables>) {
  return useQuery<WidgetsUsersQuery, WidgetsUsersQueryVariables>(WIDGETS_USERS_QUERY, options)
}

export function useRouletteEventsQuery(options?: QueryHookOptions<RouletteEventsQuery, RouletteEventsQueryVariables>) {
  return useQuery<RouletteEventsQuery, RouletteEventsQueryVariables>(ROULETTE_EVENTS_QUERY, options)
}

export function useRouletteChecksQuery(options?: QueryHookOptions<RouletteChecksQuery, RouletteChecksQueryVariables>) {
  return useQuery<RouletteChecksQuery, RouletteChecksQueryVariables>(ROULETTE_CHECKS_QUERY, options)
}

