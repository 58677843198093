import Bugsnag from "@bugsnag/js";
import { Badge, Divider, Grid, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import { useOnlineClientsCountQuery, useOnlineSalesmansCountQuery } from "graphql/Chats/OnlineClientsCountQueries";
import User from "graphql/User/User";
import { UserRole } from "graphql/apollo-graphql-generated/globalTypes";
import UserDropdown from "pages/User/components/UserDropdown";
import { OnlineSalesmansModal } from "./OnlineSalesmansModal";
import { useMemo, useState } from "react";

type OnlineCountProps = {
  count: number
  name: { singular: string, plural: string }
  onClick?: () => void
}
const OnlineCount = (props: OnlineCountProps) => {
  const { count, name, onClick } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return <div
    style={{ display: 'flex', gap: 4, alignItems: 'center', cursor: onClick ? 'pointer' : 'default' }}
    onClick={onClick}
  >
    <Badge count={count} color='#28B62C' overflowCount={1000} />
    <span>{`${count === 1 ? name.singular : name.plural}${screens?.md ? ' online' : ''}`}</span>
  </div>
}

type DashboardHeaderProps = {
  selectedWidgetIds: string[]
  onLogout: (redirectTo?: string) => void
  onOpenAgenciesSelection: () => void
  user: User
}
export const DashboardHeader = (props: DashboardHeaderProps) => {
  const { onLogout, selectedWidgetIds, user, onOpenAgenciesSelection } = props;
  const [onlineSalesmansModalVisible, setOnlineSalesmansModalVisible] = useState<boolean>(false);
  const isAdminOrManager = useMemo(() => !!user && (user?.role === UserRole.FIREFLY_ADMIN || user?.role === UserRole.AGENT_MANAGER), [user])

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { data: clientsData } = useOnlineClientsCountQuery({
    variables: { widgetIds: selectedWidgetIds }, pollInterval: 5 * 60 * 1000,
    onError(error) {
      Bugsnag.notify(error, (event) => event.setUser(user.id, user.email, user.name))
    },
  });
  const { data: salesmansData, refetch } = useOnlineSalesmansCountQuery({
    variables: { widgetIds: selectedWidgetIds }, pollInterval: 5 * 60 * 1000,
    skip: !isAdminOrManager,
    onError(error) {
      Bugsnag.notify(error, (event) => event.setUser(user.id, user.email, user.name))
    },
  });

  const clientsOnlineCount = clientsData?.onlineClientsCount || 0;
  const salesmansOnlineCount = salesmansData?.onlineSalesmansCount?.count || 0;

  return <>
    <Header style={{
      padding: '16px',
      background: '#fff',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    }}>
      {isAdminOrManager && salesmansOnlineCount > 0 ? <>
        <OnlineCount
          count={salesmansOnlineCount}
          name={{ plural: 'Corretores', singular: 'Corretor' }}
          onClick={() => setOnlineSalesmansModalVisible(true)}
        />
        <Divider type='vertical' />
      </> : null}
      {clientsOnlineCount > 0 ? <>
        <OnlineCount
          count={clientsOnlineCount}
          name={{ plural: 'Clientes', singular: 'Cliente' }}
        />
        <Divider type='vertical' />
      </> : null}
      <UserDropdown
        onLogout={onLogout}
        user={user}
        onOpenAgenciesSelection={onOpenAgenciesSelection}
      />
      <Divider type='vertical' />
      <Typography.Text type="secondary">{screens?.md ? 'Versão ' : null}{process.env.REACT_APP_VERSION}</Typography.Text>
    </Header>
    <OnlineSalesmansModal
      open={onlineSalesmansModalVisible}
      onCancel={() => setOnlineSalesmansModalVisible(false)}
      salesmans={salesmansData?.onlineSalesmansCount?.salesmans}
      refetch={refetch}
    />
  </>
}