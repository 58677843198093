import { FrownOutlined, LogoutOutlined } from '@ant-design/icons';
import { Alert, Layout, Modal, Skeleton, Spin } from "antd";
import { useEffect, useState, useRef, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import DashboardLeftSider from './components/DashboardLeftSider';
import DashboardRightSider from './components/DashboardRightSider';
import { SELECTED_WIDGET_IDS_LOCAL_STORAGE_KEY } from './DashboardRootPage';
import User, { UserContext } from 'graphql/User/User';
import PagesController, { ROUTES } from './PagesController';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { UserSettings } from 'graphql/User/UserSettings';
import castArray from 'lodash/castArray';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import NetworkErrorPage from 'components/NetworkErrorPage';
import PageNotFoundPage from './PageNotFoundPage';
import { DashboardHeader } from './components/DashboardHeader';
import PageLoadingPage from './PageLoadingPage';
const { Content } = Layout;
const confirm = Modal.confirm


export interface Props {
  // closedRoutes: JSX.Element[]
  isSocketConnected?: boolean
  onLogout: (redirectTo?: string) => void
  onRefetchAgencies?: () => void
  user: User
}

export default function DashboardAuthenticatedRootPage(props: Props) {
  const { user, onLogout, onRefetchAgencies, isSocketConnected } = props
  const settingsRef = useRef<UserSettings>(UserSettings.fromLocalStorage() || UserSettings.fromDefaultValues())

  const [selectedWidgetIds, setSelectedWidgetIds] = useState<string[]>(
    JSON.parse(localStorage.getItem(SELECTED_WIDGET_IDS_LOCAL_STORAGE_KEY)) ||
    (user && user.getAllWidgetIds())
    || []
  )
  const selectedAgencyIds = user && user.agent && user.agent.agencies
    .filter(agency => agency.chatWidgets.some(cur => selectedWidgetIds.includes(cur.id)))
    .map(agency => agency.id)

  const selectedAgencyIdsMongo1 = user && user.agent && user.agent.agencies
    .filter(agency => selectedAgencyIds.includes(agency.id))
    .map(agency => agency.parent.idsMongo)

  const selectedAgencyIdsMongo = selectedAgencyIdsMongo1 && uniq(flatten(selectedAgencyIdsMongo1))

  // Updates the local storage var every time the selection changes
  useEffect(() => {
    const prev = localStorage.getItem(SELECTED_WIDGET_IDS_LOCAL_STORAGE_KEY)
    // Do not save an empty array if the previous var does not exist
    if (!prev && selectedWidgetIds.length === 0) return

    localStorage.setItem(SELECTED_WIDGET_IDS_LOCAL_STORAGE_KEY, JSON.stringify(selectedWidgetIds))
  }, [selectedWidgetIds])

  const [rightSiderCollapsed, setRightSiderCollapsed] = useState(true)

  useEffect(() => {
    if (user) {
      if (process.env.NODE_ENV === 'production') {
        if (!user?.email?.includes('irazao.com.br') && !user?.email?.includes('sferaimobiliaria.com.br') && !user?.email?.includes('bgimobiliaria.com.br'))
          return

        // LogRocket.init('pyvtlx/firefly');

        // LogRocket.identify(user.id, {
        //   name: user.name,
        //   email: user.email,
        // });
      }
    }
  }, [user])

  const allLinks = PagesController.getAntdMenuItems({ user })
  allLinks.push({
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
    onClick: () => {
      confirm({
        title: 'Deseja realmente fazer logout?',
        okText: 'Sim',
        cancelText: 'Não',
        onOk: () => onLogout('/')
      })
    },
  })

  const allRoutes = PagesController.getReactRouterRoutes({ user, harryRoutesOnly: !!process.env.REACT_APP_HARRY_BUILD })
  if (process.env.REACT_APP_HARRY_BUILD)
    allRoutes.push(<Route key={"/"} render={() => <PageLoadingPage />} />);

  allRoutes.push(<Route key={"404"} render={() => <PageNotFoundPage />} />);

  // when showContentOnly is true, the menus will be hidden, and the main content will be in fullscreen.
  const [showContentOnly, setShowContentOnly] = useState(true) // Start as true to prevent this error: Can't perform a React state update on an unmounted component

  const location = useLocation()
  useEffect(() => {
    // will hide the menus in the 'chatOnline' page
    const chatOnlinePath = castArray(castArray(ROUTES.chatOnlineExternalChatChannel.routeProps)[0].path)[0]
    const oldChatOnlinePath = castArray(castArray(ROUTES.oldChatOnlineExternalChatChannel.routeProps)[0].path)[0]
    // removes ending '/:externalChatChannelId'
    const chatOnlinePath2 = chatOnlinePath.replace(/\/:.+$/, '')
    const oldChatOnlinePath2 = oldChatOnlinePath.replace(/\/:.+$/, '')

    const showContentOnlyNew = location.pathname.toLowerCase().startsWith(chatOnlinePath2.toLowerCase()) ||
      location.pathname.toLowerCase().startsWith(oldChatOnlinePath2.toLowerCase()) ||
      (process.env.REACT_APP_HARRY_BUILD && location.pathname === '/')

    setShowContentOnly(showContentOnlyNew)
  }, [location])

  if (!user) return <Skeleton active />

  return (
    <UserContext.Provider value={{
      user,
      selectedWidgetIds,
      selectedAgencyIds,
      selectedAgencyIdsMongo,
      refetchAgencies: onRefetchAgencies,
      isSocketConnected,
      settings: settingsRef.current,
      onLogout,
    }}>
      <Layout style={{ height: '100vh', overflow: 'auto' }}>
        {!showContentOnly && !process.env.REACT_APP_HARRY_BUILD && <DashboardLeftSider menuItems={allLinks} />}

        <Layout style={{ height: '100vh', overflow: 'auto' }}>
          {!showContentOnly && <DashboardHeader
            onLogout={() => onLogout('/')}
            onOpenAgenciesSelection={() => setRightSiderCollapsed(false)}
            selectedWidgetIds={selectedWidgetIds}
            user={user}
          />}
          <Content>
            <div style={{ padding: showContentOnly ? '0px' : '6px', height: '100%' }}>
              <ErrorBoundary
                errorFallback={
                  <Alert
                    icon={<FrownOutlined />}
                    showIcon
                    type='error'
                    message='Erro'
                    description='Ocorreu um erro inesperado. Verifique o console do navegador para mais informações.'
                  />
                }
                networkErrorFallback={<NetworkErrorPage />}
              >
                <Suspense
                  fallback={
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75vh' }}>
                      <Spin tip='Carregando página...' />
                      {/* {console.log('suspense fallback')} */}
                    </div>}>

                  <Switch>
                    {allRoutes}
                  </Switch>

                </Suspense>
              </ErrorBoundary>
            </div>
          </Content>
        </Layout>
        {!showContentOnly && <DashboardRightSider
          user={user}
          onClose={() => setRightSiderCollapsed(true)}
          collapsed={rightSiderCollapsed}
          onWidgetIdsSelectionChange={selection => { setSelectedWidgetIds(selection) }}
          selectedWidgetIds={selectedWidgetIds}
          onRefetchAgencies={onRefetchAgencies}
        />}
      </Layout>
    </UserContext.Provider>
  )
}
