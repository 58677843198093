import { Button, Result } from 'antd'

const PageNotFoundPage = () => {
  return <Result
    status="404"
    title="404"
    subTitle="Desculpe, a página solicitada não existe."
    extra={<Button
      type="primary"
      href="/"
    >
      Voltar ao início
    </Button>}
  />
};

export default PageNotFoundPage;
