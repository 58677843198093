import gql from "graphql-tag";

export const EXTERNAL_CHAT_CHANNEL_FRAGMENT = gql`
  fragment ExternalChatChannelFragment on ExternalChatChannel {
    id
    authenticationError
    authenticationidInChannel
    botEnabled
    botReactivationTimeSeconds
    chatsSyncLimit
    disableBotAfterFirstAnswer
    disableGroups
    disabled
    enableWhatsappSessionSave
    idInChannel
    shouldSendUserName
    markAsReadAfterReply
    isChatWidget
    isInternalUserChat
    isMobileBatteryPlugged
    isServerRestarting
    localServerHeartbeatDate
    messagesSyncLimit
    mobileBatteryPercent
    name
    qrCode
    shouldRestartServer
    shouldResyncMessages
    shouldResyncMessagesFromClientIds
    state
    statusDescription
    statusInChannel
    syncStatus
    syncProgressCurrent
    syncProgressMax
    lastSyncDate
    type
    instanceType

    # task info
    # ecsTaskArns
    # ecsTaskArnsLogLinks
    ecsTaskHistory {
      startDate
      ecsTaskArn
    }
    serverVersion

    key
    token
  }
`;

export const EXTERNAL_CHAT_CHANNEL_ACTION_FRAGMENT = gql`
  fragment ExternalChatChannelActionFragment on ExternalChatChannelAction {
    _id
    date
    externalChatChannelId
    # who did the action
    from
    fromId
    # status is true when typing, false when not
    status
    # who received the action
    to
    toId
    type
  }
`;

export const EXTERNAL_CHAT_MESSAGE_FRAGMENT = gql`
  fragment ExternalChatMessageFragment on ExternalChatMessage {
    _id
    _idInChannel
    clientId
    contacts {
      addresses {
        fullAddress
        type
      }
      emails {
        email
        type
      }
      name
      phones {
        phone
        type
      }
    }
    content
    createdAt
    createdAtInChannel
    date
    direction
    errorMessage
    externalChatChannelId
    forwarded
    forwardedManyTimes
    from
    fromBot
    fromId
    fromIdInChannel
    groupId
    groupIdInChannel
    hasError
    hasMedia
    location {
      description
      lat
      lon
    }
    media {
      fileName
      fileNameOrig
      mimetype
      size
      url
      urlValidUntil
    }
    quotedMessage {
      _id
      content
      direction
      from
      fromId
      to
      toId
      type
    }
    quotedMessageId
    markedForDeletionAt
    markedForDeletionUserId
    mediaDownloadPending
    processedAt
    readBy {
      date
      peer
      peerId
    }
    receivedBy {
      date
      peer
      peerId
    }
    sendSchedule
    sentAt
    templateId
    templateParams
    to
    toId
    toIdInChannel
    type
    updatedAt
  }
`;
