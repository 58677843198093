

export default function isMobile() {
  return window.innerWidth <= 450
}

export function isMobile600() {
  return window.innerWidth <= 600
}

export function isMobile768() {
  return window.innerWidth < 768
}

export function isNotebook() {
  return window.innerWidth <= 1400
}
